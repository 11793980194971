<template>
  <div class="container">
    <div class="row pt-4">
      <div class="col-8">
        <h5 class="font-weight-bolder">Pay: ${{$store.state.transactionObject.total}}</h5>
      </div>
      <div class="col-4 text-right">
        <p class="edit-items-link" @click="$router.push({ name: 'productList' })"><i class="fa fa-plus"></i> more Items</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <h5 class="pb-2 font-weight-bolder">Choose a Payment Method</h5>
          </div>
          <div class="col-6 text-right">
            <p class="cancel-sale" @click="cancelSale">Cancel sale</p>
          </div>
        </div>
        <b-form-group>
          <b-form-radio-group id="radio-group-2" v-model="paymentType" name="radio-sub-component">
            <b-form-radio value="icare" class="font-weight-bold">iCare</b-form-radio>
            <b-form-radio value="card" class="font-weight-bold">Credit/Debit Card</b-form-radio>
            <b-form-radio value="paypal" class="font-weight-bold">PayPal</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </div>
    </div>
    <b-form v-if="paymentType === 'icare'">
      <div class="row">
        <div class="col-12">
          <b-button type="submit" variant="primary" class="w-100 pay-button" @click="postTransaction">Pay</b-button>
          <b-alert :show="paymentSuccess" variant="success" dismissible>payment successfull</b-alert>
          <b-alert :show="paymentSuccess === false" variant="danger" dismissible>payment failed</b-alert>
        </div>
      </div>
    </b-form>
    <b-form v-if="paymentType === 'card'">
      <div class="row">
        <div class="col-12">
          <b-form-group>
            <b-form-input
              v-model="form.cardName"
              type="text"
              required
              placeholder="Name on Card"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group>
            <b-form-input
              v-model="form.cardNumber"
              type="number"
              required
              placeholder="Card Number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group>
            <b-form-input
              v-model="form.cardDate"
              type="text"
              required
              placeholder="Expriry Date (MM/YY)"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group>
            <b-form-input
              v-model="form.cardCvv"
              type="number"
              maxLength="3"
              required
              placeholder="CVV"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-button type="submit" variant="primary" class="w-100 pay-button" @click="postTransaction">Pay</b-button>
          <b-alert :show="paymentSuccess" variant="success" dismissible>payment successfull</b-alert>
          <b-alert :show="paymentSuccess === false" variant="danger" dismissible>payment failed</b-alert>
        </div>
      </div>
    </b-form>
    <b-form v-if="paymentType === 'paypal'">
      <div class="row">
        <div class="col-12">
          <b-form-group>
            <b-form-input
              v-model="form.email"
              type="text"
              required
              placeholder="Paypal Email Address"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-button type="submit" variant="primary" class="w-100 pay-button" @click="postTransaction">Pay</b-button>
          <b-alert :show="paymentSuccess" variant="success" dismissible>payment successfull</b-alert>
          <b-alert :show="paymentSuccess === false" variant="danger" dismissible>payment failed</b-alert>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>

export default { 
  name: 'checkout',
  data () {
    return {
      form: {},
      paymentType: 'icare',
      paymentSuccess: null
    }
  },
  watch: {
    paymentType () {
      this.paymentSuccess = null
      // console.log('this.paymentSuccess - ', this.paymentSuccess)
    }
  },
  mounted () {
    this.$store.dispatch('updateCartAmount')
  },
  methods: {
    postTransaction (e) {
      e.preventDefault()
      // console.log('transactionObject - ', this.$store.state.transactionObject)// Api implement here
      if (this.paymentType === 'icare') {
        // console.log('payment successfull')
        this.paymentSuccess = true
        // console.log('this.paymentSuccess - ', this.paymentSuccess)
      } else {
        // console.log('payment failed')
        this.paymentSuccess = false
      }
      return false
    },
    cancelSale () {
      let that = this
      this.$dialog.confirm('Are you sure to cancel this sale?').then(function (dialog) {
        // console.log('Clicked on proceed')
        that.$store.dispatch('cancelTransaction')
      }).catch(function () {
        console.log('Clicked on cancel')
      })
    }
  },
  computed: {
    cartPrice () {
      return this.$store.state.cartAmount
    }
  }
}
</script>

<style lang="scss">
.edit-items-link{
  color: #659DBD;
}
.pay-button{
  background-color: #1363a4 !important;
  border-color: #1363a4!important;
}
.cancel-sale{
  color: #659DBD;
}
</style>
