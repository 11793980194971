/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const newTransactionObject = {    // fixme: this is a TEMPLATE we use to MAKE a transaction NOT the used object
  accountNumber    : "",
  badgeNumber      : "",
  source           : 'iPos',
  workstationNumber: "",
  wksId            : "",
  checkNumber      : "",
  transactionNumber: "",
  employeeName     : "",
  employeeNumber   : 0,
  cashierNumber    : 0,
  revenueCenter    : 0,
  revenueCenterName: "",
  receiptHeader    : [],
  receiptFooter    : [],
  checkInfo        : [],
  coverCount       : 1,
  numberItems      : 0,
  taxExempt        : false,
  taxable          : 0,
  nonTaxable       : 0,
  subTotal         : 0,
  discount         : 0,
  serviceCharge    : 0,
  tax              : [0,0,0,0],
  total            : 0,
  amountPaid       : 0,
  totalDue         : 0,
  items            : []
}

let storageData = JSON.parse(localStorage.getItem('icarePos'))

export default new Vuex.Store({
  state: {
    posConfig   : null,
    items       : [],
    categories  : [],
    transactionObject: Object.assign({}, newTransactionObject),
    cartAmount  : 0,
    userLoggedIn: false,
    property    : {}
  },
  mutations: {
    initial_load (state) {
      state.userLoggedIn = storageData && storageData.userLoggedIn
    },
    update_Cart (state, payload) {
      if(payload.transaction.items.length > 0)
      {
        state.transactionObject = payload.transaction
        state.categories = payload.categories
        console.log('payload - ', payload)
      }
    },
    cancel_Transaction (state) {
      state.transactionObject = Object.assign({}, newTransactionObject)
    },
    load_selected_items (state, payload) {
      let products = state.items
      let productIndex = null
      state.transactionObject.map((item, index) => {
        if (item.selected) {
          productIndex = products.findIndex(x => x.id === item.id)
          products[productIndex].selected = true
          products.splice(productIndex, 1, products[productIndex])
        }
      })
      state.items = products
    },
    update_Cart_Price (state, payload) {
      const index = state.transactionObject.items.findIndex(x => x.itemNumber === payload.itemNumber)
      payload.total = payload.qty * parseFloat(payload.price)
      payload.total = payload.total.toFixed(2)
      state.transactionObject.items.splice(index, 1, payload)
    },
    update_Cart_Amount (state, payload) {
      if (state.transactionObject.items.length > 0) {
        let transactionObject = null
        state.transactionObject.items.map((val) => {
          transactionObject += parseFloat(val.total)
        })
        state.cartAmount = transactionObject.toFixed(2)
      }
    },
    userLogIn (state, payload) {
      if (payload) {
        state.userLoggedIn = true
      } else {
        state.userLoggedIn = false
      }
    },
    update_Items (state, payload) {
      console.log('payload -', payload)
      state.categories = []
      payload.map((val) => {
        state.categories.push({ title: val.category, id: val['_id'] })
      })
      storageData['categories'] = payload
      localStorage.setItem('icarePos', JSON.stringify(storageData))
      console.log('localStorage - ', JSON.parse(localStorage.getItem('icarePos')).categories)
    },
    last_Update (state, payload) {
      state.property = { logo: payload.propertyLogo, name: payload.propertyName }
    }
  },
  actions: {
    cancelTransaction (context) {
      context.commit('cancel_Transaction')
    },
    updateCart (context, payload) {
      context.commit('update_Cart', payload)
    },
    loadSelectedItems (context, payload) {
      context.commit('load_selected_items', payload)
    },
    updateCartPrice (context, payload) {
      context.commit('update_Cart_Price', payload)
    },
    updateCartAmount (context, payload) {
      context.commit('update_Cart_Amount', payload)
    },
    initialLoad (context) {
      context.commit('initial_load')
    },
    userLogIn (context, payload) {
      // fixme: this seems redundant as the Api gets this value and overwrites from localStorage??
      // if( payload['x-access-token']) {
      //   Api.headers[ 'x-access-token'] = payload['x-access-token'];
      // }
      context.commit('userLoggedIn', payload)
    },
    updateItems (context, payload) {
      context.commit('update_Items', payload)
    },
    lastUpdate (context, payload) {
      context.commit('last_Update', payload)
    }
  },
  modules: {
  }
})
