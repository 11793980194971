import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '../views/About.vue'
// import Login from '../views/Login.vue'
// import SignOut from '../views/SignOut.vue'
import HomeMenu from '../views/HomeMenu.vue'
import ProductList from '../views/ProductList.vue'
import CheckOut from '../views/CheckOut.vue'
//import Reports from '../views/Reports.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', // fixme: I think the root page should NOT be the login page
    name: 'login',
    // component: Login
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/signout', // fixme: I think the root page should NOT be the login page
    name: 'signout',
    // component: SignOut
    component: () => import(/* webpackChunkName: "SignOut" */ '../views/SignOut.vue')
  },
  {
    path: '/homemenu',
    name: 'homeMenu',
    component: HomeMenu,
    // component: () => import(/* webpackChunkName: "HomeMenu" */ '../views/HomeMenu.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/about',
    name: 'about-us',
    component: About,
    // component: () => import(/* webpackChunkName: "About" */ '../views/About.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/sale',
    name: 'sale',
    component: ProductList,
    // component: () => import(/* webpackChunkName: "ProductList" */ '../views/ProductList.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import(/* webpackChunkName: "Reports" */ '../views/Reports.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckOut,
    meta: { requiredAuth: true }
  },
  { // fixme: dropping to login for the moment. Later might want to handle this differently
    path: '*',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { removeToken: true } // fixme: this is a dirty fix; add a menu option to log-off, but till then, this may be a reasonable fix
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const authRequired = to.matched.some(route => route.meta.requiredAuth)
  let token = null

  if (to.matched.some(route => route.meta.removeToken)) {
    console.log('******** Hit unknown route - removing token & hitting login')
    router.app.$options.methods.clearStoredConfiguration();       // having an unknown route automatically go to login is sort of a self-healing attempt
    localStorage.removeItem('x-access-token');
    console.log("Warning: System ROUTE Error: going to login" );
    router.push({name: 'login'})
    return;
  } else {
    token = localStorage.getItem('x-access-token')
  }

  if (authRequired && !(token)) { // have token = logged in; not a security issue as the API will fail if token is tampered or missing - and technically cannot be logged in without a token so this is reasonable I think.
    next('/')
  } else {
    console.log( "before next", router );
    next()
    console.log( "after next", router);
  }
})

export default router
