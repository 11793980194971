<template>
  <div v-if="show">
    <div id="payment-heading-container" fullscreen persistent transition="fade-transition">
      <!-- <div class=" nav-menu-fullscreen"> -->
      <div class="menu-card ">
        <div class="py-3 text-center">
          <!-- <h3 class="payment-heading mr-1">{{prompt.headerPaymentSelection}}</h3> -->
          <h3 class="balance-text"> {{ prompt.headerPaymentSelection }} :: {{ prompt.headerBalanceDue }}<span style="font-weight:600;font-size:1.25em;"> {{ currency(balanceDue) }}</span></h3>
        </div>
        <div class="big-spacer mb-2"></div>
      </div>
      <!-- </div> -->
    </div>
    <div class="row ">
      <!-- <div class="col-12 col-md-7 col-lg-8 col-xl-9"> -->
        <!-- <div class="row px-2"> -->

          <!---------------------[ Select Tendering Key ]------------------->
          <div class="col-sm-12 col-md-6 col-lg-8 col-xl-8 mt-3 pl-5" v-if="activeForm === 'askpayment'" >
            <h4>Select Payment Type</h4>
            <div class="text-center mt-4">
              <b-button class="tender-button" :class="tender.class" @click="selectTender(tender)" v-for="(tender, index) in activeTenderMedia()" :key="index">
                <i :class="tender.icon"></i> {{ tender.title }}
              </b-button>
            </div>
            <hr>
            <b-button class="bg-white text-dark border-primary form-cancel-btn mt-2" @click="cancel()">Back</b-button>
          </div>

          <!--------------[ Ask Card ]------------>
          <div class=" col-12 col-lg-8 col-md-11 mt-3" v-if="activeForm === 'askcard'">

            <b-form class="badge-information p-3" @keydown.esc="cancelLookup()">
              <h4 class="font-weight-bolder pb-2 text-center">{{ selectedTender.title }}</h4>
              <b-form-group class="font-weight-bold pb-3" id="input-group-1" :label="selectedPayment.prompt.cardNumber" label-for="input-1">
                <b-input class="badge-information-input" id="input-1" ref="cardinput" type="text" autofocus autocomplete="off" placeholder=""
                  v-on:keydown.13.prevent="lookupCard('byBadge')" v-model="cardNumber"></b-input>
              </b-form-group>
              <div class="mt-4" v-if="errorMessage">
                <b-alert variant="danger" show>{{ errorMessage }}</b-alert>
              </div>
              <b-button variant="" @click="cancelLookup()" class="bg-white charge-button float-left text-dark border-primary form-cancel-btn" style="min-width: 110px">Cancel</b-button>
              <b-button @click="searchName()" class="form-submit-btn ml-2 charge-button" style="min-width: 110px">{{ prompt.searchName }}</b-button>
              <b-button @click="lookupCard('byBadge')" class="form-submit-btn ml-2 charge-button" style="min-width: 110px">{{ prompt.searchBadge }}</b-button>
            </b-form>
          </div>

          <!-- Ask Account -->
          <div class="col-12 col-lg-8 col-md-11 mt-3 scroll"  v-if="activeForm === 'askaccount'">
            <div class="row">
              <template v-if="account">
                <div class="account-button" style="margin-left:2em;" >
                  <div class="row" @keydown.esc="cancelLookup()">
                    <div class="col-12 col-md-4">
                      <div class="account-button-image-container">
                        <span v-if="account.badge.pictureUrl"><img :src="account.badge.pictureUrl" /></span>
                        <span v-else><i class="fa fa-user user-icon"></i></span>
                      </div>
                    </div>
                    <br />
                    <div class="col-12 col-md-8 ">
                      <div style="font-weight:800;font-size:1.2em;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{ account.badge.alias }}</div>
                      <div>{{  account.accountTitle }}</div>
                      <div v-if="account.roomNumber">Room: {{ account.roomNumber }}</div>
                      <div style="font-size:.8em;">{{ account.accountNumber }}</div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="!account" >
                  <!-- <div class="account-button" :style="accountListStyle(acct)"> -->
                  <div v-for="(acct, index) in accounts" :key="index" class="col-md-4 col-sm-6 col-xm-12 pl-3"  @click="selectAccount(acct)">
                    <div class="account-button"  >
                      <div class="row" @keydown.esc="cancelLookup()">
                        <div class="col-xs-12 col-sm-12 col-md-4">
                          <div class="account-button-image-container">
                            <span v-if="acct.badge.pictureUrl"><img :src="acct.badge.pictureUrl" /></span>
                            <span v-else><i class="fa fa-user user-icon"></i></span>
                          </div>
                        </div>
                        <br />
                        <div class="col-xs-12 col-sm-12 col-md-8  ">
                          <div style="font-weight:800;font-size:1.2em;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;margin-bottom:0">{{ acct.badge.alias }}</div>
                          <div>{{ acct.accountTitle }}</div>
                          <div v-if="acct.roomNumber">Room: {{ acct.roomNumber }}</div>
                          <div style="font-size:.7em;">{{ acct.accountNumber }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
              </template>
            </div>
            <br />
            <div class="mt-2 p-5" v-if="errorMessage">
              <!-- <h2>System Error</h2> -->
              <!-- <hr> -->
              <!-- account -->
              <!-- {{ account }} -->
              <!-- <hr> -->
              <!-- {{ account.total }} -->
              <!-- <hr> -->
              <!-- tenderItem -->
              <!-- {{ tenderItem }} -->
              <!-- <hr> -->
              <!-- selected tender -->
              <!-- {{ selectedTender }} -->
              <!-- <hr> -->
              <!-- postingItem -->
              <!-- {{ postingItem }} -->
              <b-alert variant="danger" show>{{ errorMessage }}</b-alert>
            </div>
            <b-button variant="" @click="cancelLookup()" class="bg-white text-dark border-primary form-cancel-btn ml-1 mt-1">Back</b-button>
          </div>
          <!-- Select Total -->
          <div class="col-lg-8 col-md-11 mt-2 col-12 text-right mt-2" v-if="activeForm === 'selecttotal'">
            <div style="margin:0 0 1em 0;padding:0 0 1em 0;border-bottom:2px solid gray;">
              <!-- <h3>Account</h3> -->
              <img v-if="account.badge.pictureUrl" :src="account.badge.pictureUrl" style="border-radius:25%;" height="96" />
              <i v-else style="text-align:left;font-size:96px;" class="fa fa-user user-icon"></i>
              <div>
                <h4 style="margin:0;">{{ account.accountTitle }}</h4>
                <div>{{ account.badge.lastName }}, {{ account.badge.firstName }}</div>
              </div>
            </div>
            <div style="display:inline-flex;width:100%;margin-bottom:1em;" @click="askPostingAmount()">
              <div style="width:100%;font-size:1.25em;text-align:center;">
                Posting: <span style="font-weight:800;font-size:1.1em;">{{ currency(postingAmount) }}</span> <span>{{
                  currency(balanceDue).subtract(postingAmount) == 0 ? "(total)" : "(partial)" }}</span>
              </div>
            </div>
            <div v-if="matchingTotals().length > 0">
              <b-button class="total-select-button" @click="selectTotal(ttl)" v-for="(ttl, index) in matchingTotals()" :key="index"
                :disabled="availableBalance(ttl) == 0">
                <!-- {{ttl}}
                  <hr>
                  {{ttl.limits}} -->
                <div v-if="availableBalance(ttl) == 0" style="color:red;font-size:1.2em;font-weight:100;">No Balance Available</div>
                <i class="fa fa-university"></i> {{ ttl.totalClassId }} : {{ ttl.title }} <div v-if="ttl.balance"> Current Bal: {{ currency(ttl.balance)
                }} <span style="padding-left:.5em;font-size:.8em;">(before posting)</span><span v-if="balanceDue > availableBalance(ttl)"
                    style="background:red;padding:.25em .5em;border-radius:.5em;margin-left:2em;color:white;font-weight:800;">(available {{
                      currency(availableBalance(ttl)) }})</span></div>
              </b-button>
            </div>
            <div v-else>
              <!-- account -->
              <!--           {{account}}-->
              <!-- <hr> -->
              <!-- {{ account.total }} -->
              <!-- <hr> -->
              <!-- tenderItem -->
              <!-- {{ tenderItem }} -->
              <!-- <hr> -->
              <!-- postingItem -->
              <!-- {{ postingItem }} -->
              <h4 style="color:red;">{{ prompt.notAvailableHere }}</h4>
            </div>
            <b-button class="bg-white text-dark border-primary form-cancel-btn mt-4" @click="cancelLookup()">Cancel</b-button>
          </div>
          <!-- Confirm Post -->
          <div class="col-lg-6 col-md-10 col-12 col-sm-10 offset-sm-1 mt-2" v-if="activeForm === 'verify'">
            <div class="row posting-card">
              <div class="col-12 text-center mb-2">
                <h5>{{ selectedTender.title }}<span style="font-size:.7em;"> to </span>{{ total.title }} Total</h5>
              </div>
              <div class="col-4">
                <img v-if="account.badge.pictureUrl" style="display:block;margin:auto;max-height: 120px;border-radius:1em;"
                  :src="account.badge.pictureUrl" />
                <i v-else style="display:block;text-align:center;margin:auto;font-size:120px;" class="fa fa-user user-icon"></i>
              </div>
              <div class="col-8 form-verify" style="font-size:.7em;font-weight:100;padding-right:3em;line-height:1.5em;">
                <table>
                  <thead>
                    <td style="background:orange;width:150px;text-align:right;height:3px;"></td>
                    <td style="background:blue;width:250px;"></td>
                  </thead>
                  <tbody>

                    <tr>
                      <td style="text-align:right;padding-right:.5em;">Name</td>
                      <td style="padding:1em 0;font-size:2em;font-weight:600;"><span>{{ account.badge.alias }}</span></td>
                    </tr>


                    <tr v-if="account.roomNumber">
                      <td style="text-align:right;padding-right:.5em;">Room #</td>
                      <td style="font-size:1.25em;font-weight:600;"><span>{{ account.roomNumber }}</span></td>
                    </tr>
                    <!--<tr><td style="text-align:right;padding-right:.5em;">Badge #</td><td style="font-size:1.25em;font-weight:600;">{{account.badge.badgeNumber}}</td></tr>-->
                    <tr>
                      <td style="text-align:right;padding-right:.5em;">Badge Name</td>
                      <td style="font-size:1.25em;font-weight:600;"><span>{{ account.badge.lastName }}, {{ account.badge.firstName }}</span></td>
                    </tr>
                    <!--                      <tr><td style="text-align:right;padding-right:.5em;">Account #</td><td style="font-size:1.25em;font-weight:600;">{{account.accountNumber}}</td></tr>-->
                    <tr>
                      <td style="text-align:right;padding-right:.5em;">Account Name</td>
                      <td style="font-size:1.25em;font-weight:600;">{{ account.accountTitle }}</td>
                    </tr>
                    <tr>
                      <td style="text-align:right;padding-right:.5em;">Current Balance</td>
                      <td style="font-size:1.25em;font-weight:600;">{{ currency(total.balance) }}</td>
                    </tr>
                    <tr>
                      <td style="text-align:right;padding-right:.5em;">Posting</td>
                      <td style="font-size:1.5em;font-weight:900;">{{ currency(postingAmount) }}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- Account # <span style="font-size:1.25em;font-weight:700;">{{ account.accountNumber }}</span><br />
                  Badge # <span style="font-size:1.25em;font-weight:700;">{{ account.badge.badgeNumber }}</span><br />
                  Badge Name <span style="font-size:1.25em;font-weight:700;">{{ account.badge.alias }}</span><br />
                  Account Name <span style="font-size:1.25em;font-weight:700;">{{ account.accountTitle }}</span><br />
                  Posting: <span style="font-size:1.25em;font-weight:900;">{{currency(tenderItem.price).multiply(tenderItem.qty)}}</span> -->
              </div>
          </div>
          <div class="row posting-card mt-1">
            <!-- <Loader class="col-12" v-if="loading" /> -->
            <div v-if="['success', 'partial'].indexOf(paymentStatus) < 0" class="col-12">
              <div class="col-12 text-center">
                <h5>Posting Confirmation</h5>
              </div>
              <b-button class="bg-white text-dark border-primary form-cancel-btn mt-2 w-100" @click="cancelLookup()">Cancel</b-button>
              <b-button @click="postTransaction()" class="form-submit-btn mt-2 w-100">OK, Post It</b-button>
            </div>
            <div class="mt-2" v-if="errorMessage">
              <b-alert variant="danger" show>{{ errorMessage }}</b-alert>
            </div>
            <div class="mt-2 col-12" v-if="paymentStatus">
              <b-alert variant="success" :show="paymentStatus === 'success'" class="w-100"><span v-if="paymentStatus === 'partial'">PARTIAL </span>
                Payment Complete</b-alert>
              <b-alert variant="danger" :show="paymentStatus === 'failed'" class="w-100">{{ errorMessage }}</b-alert>
              <!-- <b-button v-if="options.isReceiptOnDemand && paymentStatus ==='success'" @click="printReceipt()" class="form-submit-btn w-100">Print Receipt</b-button> -->

              <b-button v-if="paymentStatus === 'partial'" @click="continueTender()" class="form-submit-btn w-100">Partial Tender Complete, Let's
                Continue</b-button>
              <div v-if="paymentStatus != 'partial'">
                <!-- <b-button @click="printReceipt()" class="btn btn-info w-100 mb-3">Print Receipt</b-button> -->
                <b-button @click="goToHome()" class="btn btn-success w-100">Done/Continue </b-button>
              </div>
            </div>
          </div>
        </div>
      <!-- </div> -->
    <!-- </div> -->
    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4  d-none d-sm-none d-md-block d-lg-block d-xl-block pr-4 ">
      <order-receipt :orderDetails="transaction" :sortReceipt="options.sortReceipt" />
    </div>
  </div>
</div></template>

<script>
/* eslint-disable */

/************************************************************************************************
 **                                        NOTES                                               **
 ************************************************************************************************

  This posting sequence is becasue of the following:

  We ask for a "badge" (card) number

  A badge can belong to more than 1 account
  An account can have more than 1 total

  Each of these sections have limits and status to be verified before posting -
  while this is NOT necessarily required, it is much faster to do the preliminary
  verification at the client to keep the round-trips to the server to a min
  and provide a better user experience.

*/

import Currency from 'currency.js'
// import Loader from '@/components/Loader'
import GeneralService from '@/services/GeneralService'
import _ from 'lodash'
import OrderReceipt from '@/components/OrderReceipt'

export default {
  name: 'PaymentDialog',
  components: {
    // Loader,
    OrderReceipt
  },
  props: {
    showDialog   : { type: Boolean, required: true, default: false },
    prompt       : { type: Object, default: () => {}, required: true },
    tenderMedia  : { type: Array, default: () => [] },
    paymentType  : { type: Array, default: () => [] },
    transaction  : { type: Object, default: () => {} },                   // wjs: refactor to make this dialog not require a copy of the transaction
    options      : { type: Object, default: () => {} },
    isAdmin      : { type: Boolean, default: true },
    paymentStatus: { type: String, default: null },
    configItems  : { type: Array, default: () => [] }
  },
  data () {
    return {
      loading            : false,
      errorMessage       : "",
      activeForm         : 'askpayment',
      cardNumber         : '',
      accounts           : null,           // what we get back from the api
      account            : null,           // what we resolve too
      totals             : null,
      total              : null,
      postingAmount      : 0,             // how much gets posted, and starts as balance due.
      selectedPayment    : null,
      selectedPaymentType: null,
      selectedTender     : null,
      creditCardTemplate : {
        // to be developed in a future version
      },
      postingTemplate: {        // posting is for the API to manage a posting message
        accountNumber: "",
        badgeNumber  : "",
        revenueCenter: 0,
        tenderNumber : 0,
        tenderAmount : 0,
        tenderQty    : 1,
        totalClassId : 0,
        title        : 'sale',
        isPayment    : false,
        isMealPlan   : false,

        servingPeriod      : 0,    // Not used currently
        transactionNumber  : 0,    // Our "next" transaction number
        checkNumber        : "",
        coverCount         : 1,    // Default to 1 since "most" transactions are for one person.
        transactionEmployee: 0,    // Who is ringing this tranaction
        checkEmployee      : 0,    // for now, same as transactionEmployee
        terminalNumber     : 0,

        comment: "",
        transactionTotal: 0,   // The AMOUNT of this tranactions; all discounts/service charges applied.  transPost will ensure this balances with all the other totals

        salesItemizer: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],   // 16 sales itemizers
        taxItemizer  : [0,0,0,0],
        discount     : 0,       // discounts are represented as a negative value
        serviceCharge: 0,       // service charges are represented as positive values

        previousPay: 0,         // when split tender, this will be the amount of "all previous" tenders.

        items: [ // include items with the LAST payment that closes the transaction
          //{
          //  itemType    : { type: String, default: "item", enum: ["item", "tender", "discount", "service", "tax", "reference"] },
          //  itemNumber  : Number,   // the user-readable number
          //  title       : String,   // menu item name
          //  qty         : Number,
          //  price       : Number,
          //  taxable     : Boolean,
          //  sequence    : Number,
          //  reference   : String,
          //}
        ],

      },
      postingItem: null,      // we will build this to post to the api
      tenderItem: null,       // We will build this to post to our transaction
      tenderTemplate: {       // tender's are for THIS pos device to handle the payment
        itemNumber : 0,
        sequence   : 9999999,
        title      : '',
        category   : 'tender',
        priceLevel : 0,
        itemType   : 'tender',
        description: '',
        reference  : [],
        void       : false,
        itemOptions: {
          isTaxable: false
        },
        qty: 1,
        price: 0.00
      }

    }
  },
  computed: {
    show () {
      this.activeForm = 'askpayment';
      return this.showDialog
    },
    balanceDue () {
      return Currency(this.transaction.total).subtract(this.transaction.amountPaid).value
    }
  },
  watch: {
    isAccountFormShow () {
      if (!this.showDialog) {
        this.resetValues()
      }
    },
    paymentStatus () {
      if (this.paymentStatus === 'success' || this.paymentStatus === 'failed' || this.paymentStatus === 'partial') {
        this.loading = false;
      }
    }
  },
  activated() {
  },
  methods: {
    activeTenderMedia() {
      return _.sortBy(this.tenderMedia, "sequence");
    },
    goToHome () {
      console.log( "paymentdialg->gohome() top" );
      let goHome = false;
      if (this.paymentStatus === 'success') {
        if( this.selectedTender && this.selectedTender.options && !this.selectedTender.options.isExitSalesScreen ) {
          goHome = true;
        }
        this.$emit('resetTransaction');
        this.resetValues();
        console.log( "paymentDialog->goToHome()" , goHome );
        if( goHome ) {
          this.$emit( "home" );
        }
      } else if (this.paymentStatus === 'failed') {
        this.activeForm = 'askpayment';
        this.resetCardInformation();
      }
    },
    continueTender() {
      this.calcPostingAmount();
      this.paymentStatus = null;
      this.activeForm = "selecttotal";
    },
    tbd () {
      alert('To be developed')
    },
    getConfig( tableId, key ) {
      let c = _.find( this.configItems , {tableId: tableId, key:key });
      if( ! c ) {
        return "";
      }
      return c.value;
    },
    accountListStyle( a ) {
      if( !a.accountNumber) {
        return "";
      }

      let parts = a.accountNumber.split("-");     // fixme: make this even more generic

      let section;
      if( parts.length > 1 ) {
        section = parts[0];
      } else {
        if( parts[0].indexOf( "@" ) > 0 ) {
          section = "email"
        }
      }

      let style = this.getConfig( "account-list", section );


      return style;
    },
    availableBalance( ttl ) {
      if( !ttl.limits ) {
        return 0;
      }
      let available;

      if( ttl.totalType === 'credit' ) {
        available = Currency(ttl.limits.total).subtract(ttl.balance).value;
        if( available > 0) {
          return available;
        }
      } else {
        available = Currency(ttl.limits.total).add(ttl.balance).value;
        if( available > 0  ) {
          return available;
        }
      }
      return 0;
    },
    calcPostingAmount() {
      this.postingAmount = this.balanceDue;
    },
    askPostingAmount()  {
      // todo: ask the user for posting value.
      this.postingAmount = this.balanceDue;
    },
    resetCardInformation() {
      this.cardNumber = '';
      this.selectedPaymentType = null;
      this.account = null;
    },
    resetValues() {
      this.resetCardInformation();
      this.activeForm     = null;
      this.total          = null
      this.tenderItem     = null
      this.postingItem    = null
      this.selectedTender = null
    },
    showErrorMessage(message) {
      this.errorMessage = message;
    },
    async printReceipt() {
      // console.log( "print receipt" );
      this.$emit( "receipt" , this.receipt  );
      // await this.$root.alert( "PaymentDialog.vue ->print receipt" );
      // todo: print the receipt and STAY on this page in case the cashier wants to print > 1 receipt.  They have a DONE button when ready.
    },
    buildPostingItem() {

      let trans      = this.transaction     // the FULL transaction record
      let tender     = this.selectedTender  // the tenderMedia record we choose
      let tenderItem = this.tenderItem      // the Tender (payment) ITEM we are posting to the transaction
      let account    = this.account         // the account we just looked up
      let total      = this.total           // the account.total we are posting too

      let item = Object.assign({}, this.postingTemplate)    // let's make a NEW object that we can POST to the api

      item.accountNumber       = account.accountNumber
      item.badgeNumber         = account.badge.badgeNumber
      item.revenueCenter       = trans.revenueCenter
      item.tenderNumber        = tender.itemNumber
      item.tenderAmount        = tenderItem.price
      item.tenderQty           = tenderItem.qty
      item.totalClassId        = total.totalClassId
      item.title               = tender.title
      item.transactionNumber   = trans.transactionNumber
      item.checkNumber         = trans.checkNumber
      item.transactionEmployee = trans.employeeNumber
      item.checkEmployee       = trans.employeeNumber
      item.servingEmployee     = trans.employeeName
      item.workstationNumber   = trans.workstationNumber
      item.wksId               = trans.workstationNumber
      item.cashierNumber       = trans.cashierNumber

      item.transactionTotal = trans.total
      item.salesItemizer =  [trans.taxable+trans.nonTaxable,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],   // 16 sales itemizers
      item.taxItemizer = trans.tax
      item.discount = trans.discount
      item.serviceCharge = trans.serviceCharge
      item.previousPay = trans.amountPaid

      if (item.tenderAmount >= trans.total) {
        item.items = Object.assign({}, trans.items)
      }

      this.postingItem = item;
      return this.postingItem;

    },
    buildTenderItem() {

      let tender = this.selectedTender
      let item = Object.assign({}, this.tenderTemplate)

      item.itemNumber = tender.itemNumber
      item.title      = tender.title
      // item.price      = this.balanceDue
      item.price = Currency(this.postingAmount).value;

      this.tenderItem = item

      return this.tenderItem;
    },
    verifyTransaction() {
      // veriy badge transaction limits
      // verify account transaction limits
      // verify account class transaction limits
      // verify transaction class transaction limits
    },
    verifyBadge(badge) {
      if (!badge.active) { return showErrorMessage("Badge not active") }
      if (badge.satus.isOnHold) { return showErrorMessage("Badge is on hold") }
      if (badge.satus.isOffSite) { return showErrorMessage("Badge is on hold (2)") }  // don't show the "real" reason for security purposes
      if (this.postingAmount > badge.transactionLimit) { return showErrorMessage("Over badge transaction limit") }

      return ""
    },
    verifyAccount() {   // we assume that this.account = the selected account we want to use, so now check all the preliminary things

      let account = this.account

      if (this.selectedTender.accountClass.length > 0) {
        if (this.selectedTender.accountClass.indexOf(account.accountClassId) < 0) {
          return this.showErrorMessage( this.prompt.notAvailableHere + " (1)")
        }
      }

      if (!account.active) {
        return this.showErrorMessage("Account not active")
      }

      let badgeMessage = this.verifyBadge(account.badge);
      if (badgeMessage) {
        return this.showErrorMessage( badgeMessage )
      }

    },
    selectAccount( account ) {

      if (!account) {
        this.activeForm = 'askaccount'
      } else {
        this.account = account
        this.selectTotal()
      }
    },
    countMatchingTotals() {
      if (this.selectedTender.totalClass.length === 0) {    // nothing in the list means everything matches
        return this.account.total.length;
      }
      return _.filter(this.account.total, (t) => { return this.selectedTender.totalClass.indexOf(t.totalClassId) >= 0 }).length;
    },
    matchingTotals() {
      if (this.selectedTender.totalClass.length === 0) {    // nothing in the list means everything matches
        return this.account.total;
      }
      return _.filter(this.account.total, (t) => { return this.selectedTender.totalClass.indexOf(t.totalClassId) >= 0 })
    },
    async selectTotal(ttl) {

      this.showErrorMessage('')

      if (ttl) {  // we're trying to post to a selected total
        this.total = ttl;
      } else {
        // if we're here we don't know which ttl to post too.
        if (this.account.total.length === 1) {
          this.total = this.account.total[0];
          this.activeForm = 'selecttotal'
          return;
        } else {
          let count = this.countMatchingTotals()
          if (count === 0) {

            return this.showErrorMessage( this.prompt.notAvailableHere );
          }

          if (count === 1) {
            this.total = this.matchingTotals()[0]
            this.activeForm = 'selecttotal'
          } else {
            this.totals = this.matchingTotals()
            this.activeForm = 'selecttotal'
            return;
          }
        }
      }

      let available = this.availableBalance( this.total );
      if( available < this.postingAmount ) {
        this.postingAmount = available;
      }

      // todo: Verify that this.total is allowed to charge against this.account

      this.buildTenderItem()
      this.verifyTransaction()          // we have built everything that we can, now verify the post

      if( this.selectedTender && this.selectedTender.options && !this.selectedTender.options.isVerifyPosting ) {    // post without the verify, but the "final screen" is also inside the verify page.
        await this.postTransaction(  );
      }

      this.activeForm = 'verify'        // we're ready to post, show the user what we're about to post and get approval

    },
    searchName() {
      this.lookupCard('byName');
    },
    async lookupCard(lookupMethod) {
      this.showErrorMessage('')
      this.loading = true;
      let cardNumber = this.cardNumber || "";

      if (cardNumber.length === 0) {
        if (lookupMethod === 'byName') {
          return this.showErrorMessage( "Search Value Required")
        } else {
          return this.showErrorMessage("Card Number Required")
        }
      }

      try {
        let response;

        if (lookupMethod === 'byName'){
          response = await GeneralService.lookupAccountByName(this.cardNumber, this.selectedTender.accountClass.join(","));
        } else {
          response = await GeneralService.lookupAccount(this.cardNumber);
        }

        if (response.status === 200) {

          this.accounts = response.data;

          if (this.accounts.length > 1) {
            this.account = null
            this.selectAccount()              // setup for the "account" selection
          } else {
            this.account = this.accounts[0]   // only 1, so let's bypass the account selection
            this.selectTotal()                // we know who, now figure out what
          }

        } else {
          this.showErrorMessage( response.data.message )
        }


      } catch (error) {
        console.log(error.message)
        this.loading = false
        this.showErrorMessage(error.message)
      }
      this.loading = false;
    },
    cancelLookup () {
      this.resetValues()
      this.activeForm = 'askpayment'
    },
    selectTender(tender) {
      this.resetValues()

      this.selectedTender = tender
      this.selectedPayment = _.find(this.paymentType, { id: tender.id })

      this.errorMessage = ""
      this.activeForm = 'askcard'

      if( tender.presets && tender.presets.badgeNumber ) {
        this.cardNumber = tender.presets.badgeNumber;    // todo: do something more specific here...
        this.lookupCard('byBadge')
      }

      this.calcPostingAmount();     // default to "total"

    },
    async postTransaction( postingAmount ) {

      if( this.loading ) {      // trap a double-click
        return;
      }

      this.loading = true;
      let posting  = this.buildPostingItem()
      let auth

      try {
        let response = await GeneralService.transactionPost(posting)
        auth = response.data;
        if (response.status !== 200) {
          this.loading = false;
          return this.showErrorMessage(auth.message);
        }
      } catch (error) {
        this.loading = false;
        console.log(error.message)
        return this.showErrorMessage(error.message)
      }

      // update tha balance of the stored TTL in case this is a partial payment.
      this.total.balance = Currency( auth.total.total.chargeTotal ).subtract( auth.total.total.paymentTotal ).multiply( (auth.total.totalType)==='debit' ? -1 : 1).value;

      let tenderItem = this.tenderItem
      tenderItem.reference = []
      tenderItem.reference.push("Approved: " + auth.transactionDetail._id.slice(auth.transactionDetail._id.length - 8))
      tenderItem.reference.push(this.account.badge.badgeNumber)
      tenderItem.reference.push(this.account.badge.alias)
      tenderItem.reference.push(auth.total.title.trim() + " Bal: " + this.currency( auth.total.total.chargeTotal ).subtract( auth.total.total.paymentTotal ).multiply( (auth.total.totalType)==='debit' ? -1 : 1))

      this.printReceipt();

      this.$emit('post', tenderItem)    // now, post this payment to OUR tranaction since it was approved
      // this.showDialog = false;
      // setTimeout(() => {
      //   this.loading = false;
      // }, 500)
    },
    cancel () {
      this.$emit('cancel')
    },
    toggleDialog () {
      return !this.showDialog
    },
    currency (c) {
      return Currency(c)
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-menu-content {
  align-items: center;
  // display: flex;
  // height: 100%;
  justify-content: center;
  left: 0;
  // pointer-events: none;
  position: fixed;
  top: 0;
  transition: .2s cubic-bezier(.25,.8,.25,1);
  width: 100%;
  z-index: 9999;
  outline: none;
  background: #fff;
}
.nav-menu {
  -webkit-box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
  box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
  border-radius: 2px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  -webkit-transition: .3s cubic-bezier(.25,.8,.25,1);
  transition: .3s cubic-bezier(.25,.8,.25,1);
  width: 100%;
  z-index: inherit;
  &.nav-menu-fullscreen {
    border-radius: 0;
    margin: 0;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    background: #fff;
  }
}
/* These colors are NOT correct -- I just made the something to see how they interact in development -- they need to be made correct */
  .payment-button {
    color: white;
    width:30%;
    padding:1em;
    background: salmon;
    margin-left:auto;
    margin-right:auto;
    margin-top:4px;
    border-radius:1em;
  }

  /* These colors are NOT correct -- I just made the something to see how they interact in development -- they need to be made correct */
  .total-select-button {
    color: white;
    width:100%;
    padding:0.5em;
    font-size:1.25em;
    background: hsl(117, 39%, 23%);
    margin-left:auto;
    margin-right:auto;
    margin-top:4px;
    border-radius:1em;
        min-height: 82px;
  }

  .tender-button {
    color: white;
    width:80%;
    margin:0em 2em 1em 2em;
    padding:1em;
    //background: #8D8741;
    background: #90ADC6;
    margin-left:auto;
    margin-right:auto;
    // margin-top:4px;
    border-radius:1em;
  }

  .payment-heading {
    font-size: 24px;
  }

  .balance-text {
    font-size: 22px;
  }

  .form-verify-image-container {
    position: relative;
    width: 120px;
    height: 120px;
    overflow: hidden;
    // border-radius: 100%;
    margin-bottom: 20px;
    .form-verify-image {
      width: 100%;
      height: 120px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .account-button {
    color: #000;
    text-align:right;
    font-size:16px;
    background: #f3f3f3;
    padding: .25em .75em .25em 0;
    margin:4px;
    border-radius:1em;
    .account-button-image-container {
      width: 64px ;
      // height: 64px;
      overflow: hidden;
      img {
        border-radius:.75em;
        // height: 100%;
      }
      .user-icon {
        color: #659DBD;
        font-size: 64px;
      }
    }
  }
  .badge-information{
    background-color: #f7f7f7;
    border-radius: 15px;
    .badge-information-input{
      border-radius: unset !important;
    }
  }
  .badge-button {
    color: white;
    width:30%;
    padding:1em;
    background: seagreen;
    margin-left:auto;
    margin-right:auto;
    margin-top:4px;
    border-radius:1em;
  }

 .function-button {
    width:94%;
    font-size:1em;;
    border-radius:1em;
    background: coral;
    padding:1em;
    margin:1em;
  }

  .form-submit-btn {background-color: #659DBD; border: 1px solid #659DBD !important;}
  .form-cancel-btn {border: 1px solid #659DBD !important;}

.menu-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & .menu-card__text {
    padding: 16px;
    width: 100%;
  }

  & .big-spacer {
    width: 100%;
    height: 10px;
    background: #193628;
    mix-blend-mode: normal;
    opacity: 0.1;
  }

  & .xbig-spacer {
    width: 100%;
    height: 100px;
  }

  & .menu-title {
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #193628;
    margin-top: 18px;
    margin-bottom: 50px;
  }

  & .menu-item {
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #3F464D;
    padding: 15px 0px;
    border-bottom: solid 1px #19362821;
    cursor: pointer;
  }

  & .menu-item-2 {
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #3F464D;
    padding: 15px 0px;
    border-top: solid 1px #19362821;
    cursor: pointer;
  }

  & .sign-out {
    color: red;
  }

}

// @media (max-width:425px)  {
//   .account-button {
//     font-size:12px;
//     // width: 155px;
//     // height:110px;
//     .account-button-image-container {
//       width: 60px !important;
//       height: 60px;
//       img {
//         width: 100%;
//         height: 100%;
//       }
//     }
//   }
// }

// .super-blue {
// color:blue;
// background:lime;
// font-size:1.4em;
// }

.posting-card {
  background: #f5f5f5;
  border-radius: 10px;
  padding: 10px 0;
}


@media screen and (max-width: 530px) {
  .charge-button {
    width: 100% !important;
    margin-left: 0 !important;
    margin-bottom: 10px !important;
  }
}
</style>
