// eslint-disable 
'use-strict';

import Currency                  from "currency.js";
// import axios from "axios";
import Moment                    from "moment";
import GeneralService from '@/services/GeneralService';

var FMT   = {},
    Width = 40;

export default FMT;

(function(){


  FMT.init = async (outputWidth = 40) => {
    Width = outputWidth;
    return true;
  }

  FMT.wordWrap = (text, maxWidth = Width) => {
    return text.split("\n").map(function(line) {
        var pos = 0;
        return line.split(/\b/).map(function(word) {
            pos += word.length;
            if(pos > maxWidth) {
                pos = 0;
                return "\n" + word.trimLeft();
            }
            return word;
        }).join("");
    }).join("\n");
  }

  FMT.makeNumberFixed = (value, len = 4) =>{
    if (value === "") {
      return " ".repeat(len)
    }
    let r = (" ".repeat(len) + value.toString())
    return r.substring((r.length-len),(r.length-len)+len)
  }

  FMT.makeStringFixed = (value, len = 24) => {
    return (value.toString() + " ".repeat(len)).substring(0, len)
  }

  FMT.makeStringFixedRight = (value, len = 24) => {
    if (value.length > len) {
      return value.substring(0,len)
    }
    return " ".repeat(len - value.length) + value;
  }

  FMT.makeCurrencyFixed = (value, len = 10) => {
    if (value === "") {
      return " ".repeat(len)
    }
    let r = (" ".repeat(len) + Currency(value).format())
    return r.substring((r.length-len),(r.length-len)+len)
  }

  FMT.makeTextCenter = (a,len=Width) => {
    if (a.length > len) {
      return a.substring(0, len);
    }
    return (" ".repeat((len/2) - (a.length / 2)) + a + " ".repeat((len/2)-(a.length / 2)+1)).substring(0,len)
  },

  FMT.qtyTitlePrice = (qty, title, price) => {
    let line = ("   " + qty.toString()).slice(-4) + " " + (title + " ".repeat(Width)).slice(0, outputWidth-15) + ("     " + Currency(price)).slice(-10);
    return line;
  }
 
  FMT.makeLineItem = (qty, title, price) => {
    let line = ("   " + qty.toString()).slice(-4) + " " + (title + " ".repeat(Width-10)).slice(0, Width-15) + ("     " + Currency(price)).slice(-10);
    return line;
  }

  FMT.ml3 = (a, b, c) => {
    return FMT.makeNumberFixed(a,4) + " " +  FMT.makeStringFixed(b, Width-16) + " " + FMT.makeCurrencyFixed(c, 10)
  }

  FMT.ml3r = (a, b, c) => {
    return FMT.makeNumberFixed(a,4) + " " +  FMT.makeStringFixedRight(b, Width-16) + " " + FMT.makeCurrencyFixed(c, 10)
  }

  FMT.ml2 = (b,c) => {
    return " ".repeat(5) +  FMT.makeStringFixed(b, Width-16) + " " + FMT.makeCurrencyFixed(c, 10)
  }

  FMT.ml2r = (b, c) => {
    return " ".repeat(5) + FMT.makeStringFixedRight(b, Width-16) + " " + FMT.makeCurrencyFixed(c, 10)
  }

  FMT.ml1 = (b) => {
    return " ".repeat(5) +  FMT.makeStringFixed(b, Width-5)
  }

  FMT.mt1 = (a) => {
    return FMT.makeTextCenter(a);
  }

  FMT.mt2 = (a, b) => {
    return FMT.makeStringFixed(a, Width/2) + FMT.makeStringFixedRight(b, Width/2);
  }

  FMT.mt3 = (a, b, c ) => {
    return FMT.makeStringFixed(a, 10) + FMT.makeTextCenter(b,Width-20) + FMT.makeStringFixedRight(c, 10);
  }


})();
