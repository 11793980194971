<template>
  <div fullscreen persistent transition="fade-transition" class="" >
    <!-- <div class="nav-menu nav-menu-fullscreen"> -->
    <div v-if="$root.userData">
      <img class="tenant-logo" :src="$root.userData.logo" alt="">
      <div style="font-size:3em;font-weight:800;text-align:center;">{{ $root.userData.tenantName }}</div>
    </div>
    <div>
      
      <div class="menu-card">
        <!-- <b-button class="function-button"  @click="doLogOut()"><i v-if="canSignOut" class="fa fa-sign-out-alt" aria-hidden="true"></i><i v-else class="fa fa-exclamation-triangle" aria-hidden="true"></i> Sign Out</b-button> -->
        <!-- <b-button class="function-button"><i v-if="canSignOut" :class="mainMenu[0].icon" aria-hidden="true"></i><i v-else class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{mainMenu[0].title}}</b-button>
        <b-button class="function-button"><i v-if="canSignOut" :class="mainMenu[1].icon" aria-hidden="true"></i><i v-else class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{mainMenu[1].title}}</b-button>
        <b-button class="function-button" @click="doLogOut()"><i v-if="canSignOut" :class="mainMenu[2].icon" aria-hidden="true"></i><i v-else class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{mainMenu[2].title}}</b-button>
        <div class="big-spacer"></div>

        <b-button v-if="options.canSetup" class="standard-button" @click="tbd()"><i class="fa fa-cog"></i> Setup</b-button>
        <b-button v-if="options.canReports" class="standard-button" @click="reports()"><i class="fa fa-file-alt"></i> Reports</b-button>
        <b-button v-if="options.canReports" class="standard-button" @click="tbd()"><i class="fa fa-file-alt"></i> Transaction List</b-button> -->
        <div class="row">
          <div class="col-12">
            <h2 class="">Main Menu</h2>
          </div>
        </div>
        <div class="big-spacer"></div>
        <div>
          <template v-for="(item, index) in mainMenu">
            <div class="menu-card-items" :key="index" @click="doAction(item.action)"><i :class="item.icon" aria-hidden="true"></i> <span class="pl-4">{{item.title}}</span> </div>
          </template>
        </div>
        <div v-if="$root.userData">
          Welcome, {{ $root.userData.alias }}
        </div>
      </div>
    </div>
    <div>
      <img alt="iPOS logo" class="ipos-logo" src="../assets/iPosCafe-Logo.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'homeMenu',
  props: {
  },
  data () {
    return {
      posConfig: {},
      mainMenu: [],
    }
  },
  computed: {
  },
  created () {


    this.mainMenu = JSON.parse(localStorage.getItem('mainMenu'))
    this.posConfig = JSON.parse(localStorage.getItem('posConfig'))

    console.log( "HomeMenu" , this.mainMenu, this.posConfig );
  },
  methods: {
    doAction (action) {
      // todo: (wjs) verify if this user has permissions to do this action.
      this.doChangeRoute(action)
    },
    tbd () {
      alert('To be developed')
    },
    toggleDialog () {
      return !this.showDialog
    },
    hideDialog () {
      // this.showFunctionsDialog()
      this.showDialog = false
    },
    doChangeRoute (routeName) {
      this.$router.push({ name: routeName })
    },
  }
}
</script>

<style lang="scss" scoped>
.nav-menu-content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: .2s cubic-bezier(.25,.8,.25,1);
  width: 100%;
  z-index: 9999;
  outline: none;
  background: #fff;
}
.nav-menu {
  -webkit-box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
  box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
  border-radius: 2px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  -webkit-transition: .3s cubic-bezier(.25,.8,.25,1);
  transition: .3s cubic-bezier(.25,.8,.25,1);
  width: 100%;
  z-index: inherit;
  &.nav-menu-fullscreen {
    border-radius: 0;
    margin: 0;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
  }
}
/* These colors are NOT correct -- I just made the something to see how they interact in development -- they need to be made correct */
  .standard-button {
    color: white;
    width:30%;
    padding:1em;
    background: salmon;
    margin-left:auto;
    margin-right:auto;
    margin-top:4px;
    border-radius:1em;
  }

 .function-button {
    width:94%;
    font-size:1em;;
    border-radius:1em;
    background: coral;
    padding:1em;
    margin:1em;
  }

.menu-card {
  display: flex;
  flex-wrap: wrap; /* Allows the cards to wrap onto the next line if there's not enough space */
  justify-content: space-between; /* Distributes space evenly between the cards */
  padding: 20px; /* Adds some space around the cards */
  font-size:1.3em;
  // justify-content: center;
  align-items: center;
  .menu-card-items {
    background:#ccc;
    border-radius:1em;
    width:400px;
    // flex: 1 1 400px; /* Sets the flex grow, shrink, and basis values */
    margin: 1rem; /* Provides space around the cards */
    padding: 1rem; /* Padding inside the cards */
    border: 1px solid #ccc; /* Border for the cards */
    box-sizing: border-box; /* Ensures padding and border are included in the width and height */

    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);  /* Adds a shadow effect */
    transition: 0.3s;                         /* Adds a transition effect when hovering over the card */

    // padding-left: 24px;
    // padding-top: 17px;
    // padding-bottom: 17px;
    // font-size: 20px;
    // border-bottom: 0.5px solid gray;
  }
  .menu-card-items:hover {
    background: #fbeec1;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); /* Enhances the shadow effect when hovering over the card */
  }

  flex-direction: column;
  // & .menu-card__text {
  //   padding: 16px;
  //   width: 100%;
  // }

  & .big-spacer {
    width: 50%;
    // text-align:center;
    height: 10px;
    background: #193628;
    mix-blend-mode: normal;
    opacity: 0.1;
  }

  & .xbig-spacer {
    width: 100%;
    height: 100px;
  }

  & .menu-title {
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #193628;
    margin-top: 18px;
    margin-bottom: 50px;
  }

  & .menu-item {
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #3F464D;
    padding: 15px 0px;
    border-bottom: solid 1px #19362821;
    cursor: pointer;
  }

  & .menu-item-2 {
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #3F464D;
    padding: 15px 0px;
    border-top: solid 1px #19362821;
    cursor: pointer;
  }

  & .sign-out {
    color: red;
  }

}
.tenant-logo {
  // position:relative;
  // top:-55px;
  // left: -120px;
  // // left:-20%;
  margin:auto;
  overflow: hidden;
  // margin:0;
  // padding:1em;
  // max-height: 512px;
  height:30vh;
}
.ipos-logo {
  position:absolute;
  bottom: 0;
  margin: 2em;
  height: 64px;
}

.about-us-icon {
  font-size: 30px;
}
.about-us-close-icon { 
  position: absolute;
  top: -6px;
  right: 0;
  button:focus {
    outline: none
  }
}
</style>
