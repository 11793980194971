import Vue from 'vue';
import App from './App.vue';
import router from './router';        // importing OUR router module
import './registerServiceWorker';
import VueAnalytics from 'vue-analytics';

import store from './store';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import axios from 'axios';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import '@/assets/scss/styles.scss';      // our system classes

import { version } from '../package.json';

// import numericKeypad from 'vue-numkeyboard';
// import 'vue-numkeyboard/style.css';

Vue.config.productionTip = false;

Vue.use(VuejsDialog);
Vue.use(BootstrapVue);
// Vue.use(numericKeypad);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;
axios.defaults.headers.common[ 'x-access-token' ] = null;

Vue.use(VueAnalytics, {
  id: 'UA-128966409-6', // iPos.cafe
  router,
  autoTracking: {
    pageviewTemplate (route) {
      // console.log( "pageviewTemplate()" , route );
      let o = {}
      if (typeof route.matched !== 'undefined') { // Figure if matched is present, it's an array with at least 1 element
        o.title = route.matched[ 0 ].name // seems to be the same as route.name, but for consistency used the matched name
        if (route.matched[ 0 ].path !== '*') { // let's capture the 404's so this is the "catch-all" we will replace.
          o.path = route.matched[ 0 ].path
          o.location = route.matched[ 0 ].path // The matched route, which is the raw route definition, as our location
        } else {
          o.path = route.path // we hit *, which would mean a 404, so use the original url so we capture it.
          o.location = window.location.href
        }
      } else {
        o.title = route.name
        o.path = route.path
        o.location = window.location.href
      }
      return o
    }
  }
})



/***************************************************************
                        GLOBAL Stuff
                      Use with caution
****************************************************************/

import DAH from '@/lib/dataAccessHelpers'
import GeneralService from '@/services/GeneralService'

new Vue({
  router,
  render: h => h(App),
  store,
  data: {
    // return {     // not in root
      appTitle   : "iPOS Cafe",
      appVersion : version,
      posConfig  : null,
      userData   : null,
      accessToken: null
    // }
  },
  created() {
    console.log( "-------------[ main.js created" );
    let user = localStorage.getItem('user' );
    if( user ) {
      this.userData = JSON.parse(user);
    }
  },
  methods: {
    wolfieGlobal() {
      alert("yep, you hit global");
    },
    async getCategories( categories ) {
      if( categories ) {
        localStorage.setItem( "categoryList" , categories );
        await DAH.getCategories( categories );

        return;
      } else {
        alert( "oops! Called with no category categories" );
      }
    },
    isAdmin() {
      if( !this.userData ) {
        return false;
      }
      console.log( "checking for admin" , this.userData );
      return this.userData.isAdmin;
    },
    logout() {
      this.clearStoredConfiguration();
      localStorage.removeItem('x-access-token')
    },
    async login( user ) {
      console.log( "user Login" , user );

      this.setAccessToken(user[ 'x-access-token' ]);
      delete user[ 'x-access-token' ];

      localStorage.setItem('user', JSON.stringify(user))
      this.userData = user;

      if(await this.getPosConfig()) {
        this.home();
      }

    },
    home() {
      this.$router.push({name: 'homeMenu'});
    },
    getPrompt( p ) {
      return this.toCapitalizedWords(p);
    },
    toCapitalizedWords(name) {
      var words = name.match(/[A-Za-z][a-z]*/g) || [];
      return words.map(this.capitalize).join(" ");
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.substring(1);
    },
    setAccessToken(token) {
      console.log( "setAccessToken()", token );
      localStorage.setItem('x-access-token', token);
      this.accessToken = token;
    },
    clearStoredConfiguration() {
      localStorage.removeItem('lastUpdateId'); // force download of new config, regardless
      localStorage.removeItem('categories');
      localStorage.removeItem('categoryList');
      localStorage.removeItem('posConfig');
      localStorage.removeItem('mainMenu');
      localStorage.removeItem('dailyTotals');
    },
    async confirmYesNo(title, icon = "question", cancelButtonText = "No", confirmButtonText = "Yes") {
      let result = await this.$swal.fire({
                                           icon             : icon,
                                           title            : title,
                                           showCancelButton : true,
                                           cancelButtonText : cancelButtonText,
                                           confirmButtonText: confirmButtonText,
                                           allowEscapeKey   : true,
                                           allowEnterKey    : true
                                         });

      if(result.dismiss) {
        return false;
      }

      return true;
    },
    async confirm(title, icon = "question") {
      let result = await this.$swal.fire({
                                           icon            : icon,
                                           title           : title,
                                           showCancelButton: true,
                                           allowEscapeKey  : true,
                                           allowEnterKey   : true
                                         });

      if(result.dismiss) {
        return false;
      }

      return true;
    },
    async alert(title, icon = 'success') {
      let result = await this.$swal.fire({
                                           icon          : icon,
                                           title         : title,
                                           allowEscapeKey: true
                                         });
    },
    async getPosConfig() {

      let categoryList;

      try {
        let lastUpdateId = localStorage.getItem( 'lastUpdateId' ) || '';

        if( ! localStorage.getItem( "posConfig" )) {
          lastUpdateId = "";
        }

        let response   = await GeneralService.getPosConfig( lastUpdateId ) // always get config on startup

        console.log( "getPosConfig response:", response );

        if( response ) {
          if( response.data && response.data.workstationConfig ) {
            categoryList = response.data.workstationConfig.categories.join(",");
          }
        }

        if( response.status === 204 ) {   // no data response. (we already have it)
          categoryList = localStorage.getItem( "categoryList" );
          await this.getCategories(categoryList);     // will be a nop() if we have it already
          return true;
        }

        if( response.status === 200  ) {
          let configData = response.data;

          if( Object.entries(configData).length === 0 && configData.constructor === Object) {   // Just in case it comes in empty.
            await this.getCategories(categoryList);
            return true;
          }

          // fixme: need to replace the api url somehow so we start using the one we just got back form config.
          // configData.apiUrl - url starts in constants, but may need to be hydrated to the store on startup -- dunno

          // now separate out some of the config stuff we're going to separately without reloading the entire config - these get updated with every transaction
          localStorage.setItem( 'dailyTotals' , JSON.stringify( configData.dailyTotals ));
          delete configData.dailyTotals;

          localStorage.setItem( 'lastUpdateId', configData.lastUpdateId );
          localStorage.setItem( 'mainMenu', JSON.stringify(configData.workstationConfig.mainMenu));

          if( !localStorage.getItem( 'nextTransactionNumber' )) {
            localStorage.setItem( 'nextTransactionNumber' , configData.transactionNumber || configData.terminalId * 1000);
            delete configData.transactionNumber;
          }

          delete configData.workstationConfig.mainMenu;

          this.posConfig = configData;
          localStorage.setItem( 'posConfig', JSON.stringify(configData));

        } else {
          // alert("posConfig 1:" +  response.data.message);
          await this.alert( response.data.message, "warning");
          return false;
        }
      } catch( error ) {  // if we're here, we have a 500+ status or something bad
        // alert( "posConfig 2:" + error.message );
        await this.alert( error.message, "warning");
        return false;
      }


      localStorage.removeItem( "categories" );
      await this.getCategories( categoryList );

      return true;
    },
    alertMessage( title,  message = null) {
        this.$bvToast.toast( message, {
        title: title,
        variant: 'warning',
        solid: true
      })
   }
  }
}).$mount('#app')
