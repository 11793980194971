<template>
  <div class="menu-item-container" :class="(item.qty !== 0) ? 'item-selected' : ''" @click="select(item)">
    <img :src="item.pictureUrl" class="w-100 item-img" alt="" />
    <div class="item-title" :style="(item.title.length>20) ? (item.title.length>30) ? 'font-size:.85em;' : 'font-size:1em;' : ''">
      {{item.title}}
    </div>
    <div class="item-price">{{ (item.options.openItem) ? 'OPEN' :  parseFloat(item.price[priceLevel]).toFixed(2) }}</div>
<!--    <div v-if="item.options.openItem===true" class="item-price"  @click.stop="" ><b-input style="width:5em;" v-model=item.price[priceLevel] /> </div>-->
    <div class="overlay" :class="item.itemStyle"></div>
    <div v-if="item.qty !== 0" class="trash-icon show-hand" @click.stop="removeOne(item)"><i class="fa fa-trash" /></div>
    <div v-if="item.qty !== 0" class="item-qty">{{item.qty}}</div>
    <!-- <div v-if="item.options.openItem===true" class="item-open-item"><i class="fa fa-circle"/></div> -->
  </div>
</template>
<script>

// import Keypad from "@/components/Keypad";
// import Keypad from "vue-numkeyboard"

export default {
  name: 'ts-menuItem',
  components: {
    // Keypad
  },
  props: {
    item: {type: Object, default: () => {}},
    priceLevel: { type: Number, default: 0 },
    backgroundColor: { type: String, default: "" }
  },
  data () {
    return {
      showKeyboard: false
    }
  },
  mounted () {

  },
  methods: {
    select( item ) {

      // if( item.options.openItem ) {
      //   if( ! this.showKeyboard ) {
      //     console.log( "showing keyboard" );
      //     this.showKeyboard = true;
      //     return;
      //   }
      // }

      this.showKeyboard = false;              // ensure off, always
      item.priceLevel = this.priceLevel;      // what we are taking in here
      this.$emit( "select", item );
    },
    removeOne( item ) {
      this.$emit("removeOne", item );
    }

  }
}
</script>
<style lang="scss"  >

.menu-item-container {
  border-radius:.75em;
  position: relative;
  height: 110px;
  overflow: hidden;
  transition: background 2s ease;    // 14-Jun-21 wjs :: Dont think this is working

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background: rgba(6,49,122,.8);
    background: rgba(64,0,64,.8); //
    // background:rgba(51,54 ,82,.8);

    // transition: background .5s ease;
  }

  .aqua {
    background:  rgba(00,255,255,.8);
  }

  .purple {
    //background: #3b3b76;
    background: rgba(66,95,145,.8); //
    //background: rgba(6,49,122,.8);
  }

  .blue {
    //background: #3b3b76;
    //background: rgba(64,0,64,.8); //
    background: rgba(6,49,122,.8);
    //background: rgba(6,49,122,.8);
  }

  .coral {
    background: rgba(155,127,80,.8);
  }

  .green {
    //background: #3b3b76;
    background: rgba(19,135,135,.8);
  }

  .light-green {
    //background: #3b3b76;
    background: rgba(90,134,134,.8);
  }

  .steelblue {
    background: rgba(70,130,180,.8);
  }

  .gold {
    //background: #3b3b76;
    background: rgba(202, 130, 0, 0.8);
  }

  .gray {
    //background: #3b3b76;
    background: rgba(135, 135, 135, 0.8);
  }


  .item-title {
    position: absolute;
    //background:lime;
    width:80%;
    left: 50%;
    top: 50%;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    z-index: 1;
    // transition: top .5s ease;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 767px) {
      font-size: 14px;
      width: 100%;
      white-space: pre-wrap;
      padding: 0 5px;
    }
  }

  .item-price {
    z-index:1;
    position:absolute;
    bottom:4px;
    right:8px;
    font-size:1em;
    font-weight:500;
    // color: black;
    color:lightgray;
  }

  .item-open-item  {
    display: block;
    font-size: 1.5em;
    position: absolute;
    left: 10px;
    bottom: 6px;
    text-shadow: 2px 2px 1px black;
    color:green;
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

 .trash-icon {
    z-index:2;      // make sure the user can hit this target.
    display: block;
    font-size: 1.1em;
    position: absolute;
    left: 10px;
    top: 6px;
    text-shadow: 2px 2px 1px black;
    color:rgba(223,197,151,1);
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  .item-qty{
    display: block;
    font-size: 1.5em;
    position: absolute;
    z-index:1;
    right:10px;
    top:6px;
    font-weight:500;
    //color: #8D8741;
    // color: rgba(141,135,65,.7);
    color: lightgray;
    text-shadow: 2px 2px 1px black;
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    height: 120px;
  }

  .item-img {
    position: absolute;
    height: 100%;
    left: 0;
  }

   //.selected:before {
   //  position: absolute;
   //  background: rgba(0,0,0, .3);  // <-- not used??
   //  content: "";
   //  width: 100%;
   //  height: 100%;
   //}


}
  .item-selected {
    background: rgba(0, 0, 0, 1);
  }

</style>
