/* eslint-disable */
'use-strict';

// import { Buffer } from 'node:buffer'; // future node version; V14 is static
import Currency from "currency.js";
import axios    from "axios";
import Moment   from "moment";
import FMT      from "./formattingLib.js";
// import GeneralService from '@/services/GeneralService';

var EPSON        = {},
    printer      = null,
    printerIp    = "",
    epos         = null,
    isPrintError = false,
    isPrinting   = false;

function addHeader(ticket, order) {

  ticket.addTextSize(1, 1);
  ticket.addText("-----------------------------------\n\n");
  ticket.addText(Moment(order.orderDate).format("dddd, DD-MMM") + "\n");
  ticket.addText("-----------------------------------\n\n");

  return ticket;
}

FMT.init();

export default EPSON;

(function() {

  EPSON.init = async (ip, printerName = "local_printer") => {
    printerIp = ip;
    if(!ip) {
      return true;
    }

    try {
      // let result = await EPSON.verify(ip); // tries to log in and get the cors aligned

      let tok = 'epson:epson';
      let hash = Buffer.from(tok).toString('base64')
      let auth = 'Basic ' + hash;
      // axios.get(`https://${printerIp}`, {headers : { 'Authorization' : auth }})


      // const config = {
      //   headers: { "Access-Control-Allow-Origin": "*" ,
      //              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS"
      //            },
      //   crossOrigin: true,
      //   changeOrigin: true
      // };
      //
      // axios.get(`https://${printerIp}`, config)
      //      .then(function(response) {
      //        console.log( "-----------------------------------------------------");
      //        console.log(response.data);
      //        console.log(response.headers['Authorization']);
      //        console.log( "-----------------------------------------------------");
      //      }).catch(err => console.error("----------------------------------\n\noops--------------------\nget auth stuff failed" , err));
      //



      // const userId = 'epson';
      // const password = 'epson';
      //
      // const url = `http://${printerIp}/cgi-bin/epos/service.cgi?devid=${printerName}&timeout=60000`; // Replace with your actual endpoint URL
      //
      // const config = {
      //   headers: {
      //     // 'Access-Control-Allow-Origin': '*', // Replace with the appropriate CORS header value
      //     "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      //     "Access-Control-Allow-Headers": "Content-Type, Authorization, access-control-allow-origin",
      //     "Content-Type": "application/json",
      //     "Accept": "application/json",
      //     'Authorization': `Basic ${Buffer.from(`${userId}:${password}`).toString('base64')}`
      //   }
      // };
      //
      // axios.get(url, config)
      //      .then(response => {
      //        // Handle the response
      //        console.log("here is what I got back:" , response.data);
      //      })
      //      .catch(error => {
      //        // Handle the error
      //        console.error("new axios error" , error);
      //      });


      printer = `https://${printerIp}/cgi-bin/epos/service.cgi?devid=${printerName}&timeout=60000`;
      epos    = new epson.ePOSPrint(printer);

      console.log("ePOS Created", epos);


    } catch(error) {
      console.log("EPSON.init error:" , error.message);
      return false;
    }

    epos.onreceive = async (res) => {
      console.log("onreceive", JSON.stringify(res, null, 2));
      if(!res.success) {
        isPrintError = true;
        isPrinting   = false;
        console.log("No Joy:", JSON.stringify(res, null, 2));
        this.$emit("printError", res);
        // alert("Printer Error " + res.code + "\nDevice Name:" + printerIp + "\nTarget Address:" + printer );
      } else {
        if(isPrinting) {
          isPrinting = false;
          this.$emit("printComplete");
        }
      }
    }

    return true;
  }

  EPSON.startTicket = () => {
    //Create an ePOS-Print Builder object

    let ticket = new epson.ePOSBuilder();

    //Create a print document
    ticket.addTextLang('en')
    ticket.addTextSmooth(true);
    ticket.addTextFont(ticket.FONT_A);
    ticket.addTextAlign(ticket.ALIGN_CENTER);
    return ticket;
  }

  EPSON.printList = async (list) => {
    let ticket = EPSON.startTicket();
    for(let i = 0; i < list.length; i++){
      ticket.addText(list[ i ] + "\n");
    }
    ticket.addText("\n\n\n");
    ticket.addCut(ticket.CUT_FEED);   // use the epson method, NOT ours
    try {
      //Send the print document
      let request = ticket.toString();

      console.log("epos", epos, request);

      await epos.send(request);
    } catch(error) {
      console.log(error.message);
      // await this.$root.alert(error.message);
      this.$root.alert(error.message);                     // fixme: add the root level alert and confirm
    }
  }

  EPSON.addCut = (ticket) => {
    return ticket.addCut(ticket.CUT_FEED);
  }

  EPSON.addItems = (ticket, order) => {
    return ticket;
  }

  // NOTE: this step does NOT seem to be working correctly
  EPSON.verify = async (ip) => {
    return
    // let url = "https://epson:epson@" + ip;
    // let url = "https://cors.io?https://" + ip;     // fixme: this is a hack to get around the CORS issue
    // let url = "https://" + ip;

    console.log("EPSON.verify()");

    // await this.$root.confirm( "verify printer " + ip);
    try {
      let auth = {
        username: "epson",
        password: "epson"
      }

      let ePrinter = axios.create({
                                    headers: {
                                      // 'Accept':'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
                                      // 'Content-Type': 'application/x-www-form-urlencoded', 'Accept'      : 'application/x-www-form-urlencoded', 'Authorization' : 'Basic
                                      // ZXBzb246ZXBzb24=',
                                      'Content-Type'               : 'application/json',
                                      'Accept'                     : 'application/json',
                                      'Access-Control-Allow-Method': 'GET, POST, PUT, DELETE, OPTIONS',
                                      'Access-Control-Allow-Origin': '*'
                                    }
                                  });

      let result = await ePrinter.get(url);

      console.log("verify result", result);

      return result;

    } catch(error) {
      console.log("---------------------------------- oops, verify threw an error ", error.message);
    }
  }

  //---------------------------------------------------------------------------------------[ Cancel printing ]
  EPSON.cancel = () => {
    isPrinting = false;
  },

    EPSON.send = async (ticket) => {
      var request = ticket.toString();
      try {
        await epos.send(request);     // send the document to the printer
      } catch(error) {
        console.log(error.message);
        await this.$root.alert(error.message);
      }
    }

  //---------------------------------------------------------------------------------------[ print an order ]
  // EPSON.printReceipt = async ( order ) => {

  //   if( ! printer ) {
  //     if( ! printerIp ) {
  //       console.log( "no printer defined" );
  //       return this.$emit( "error" , "no printer defined" );
  //     }
  //     if( ! EPSON.init(printerIp)) {
  //       console.log( "unable to init printer" );
  //       return this.$emit( "error" , "unable to init printer" );
  //     }
  //   }

  //   let ticket = EPSON.startTicket();
  //   addHeader( ticket , order );
  //   addItems( ticket , order );
  //   send( ticket );

  // }

  // ticketAddOrderHeader(builder, order) {

  //   builder.addTextSize(2, 1);
  //   builder.addText(order.alias + "\n");
  //   builder.addText("\n");

  //   builder.addTextSize(1, 1);

  //   builder.addText( "ticketAddOrderHeader()")
  //   // if(order.grade.length > 0) {
  //   //   builder.addText("Grade: " + order.grade + "\n");
  //   // }
  //   // if(order.homeRoom) {
  //   //   builder.addText("Home Room: " + order.homeRoom + "\n");
  //   //   builder.addText( this.getClassDescription( order.homeRoom ) + "\n" );
  //   //   builder.addTextSize(2, 1);
  //   //   builder.addText( this.rotationLocation( order.homeRoom ) + "\n\n" );
  //   //   builder.addTextSize(1, 1);
  //   // }
  //   // builder.addText(Moment(order.orderDate).format("dddd, DD-MMM-YY") + "\n");

  //   builder.addText("\n");
  //   builder.addText("\n");

  // },
  // ticketAddOrderItems(builder, order) {
  //   builder.addTextAlign(builder.ALIGN_LEFT);
  //   builder.addTextSize(1, 1);
  //   builder.addHLine(1, 501, builder.LINE_THIN);
  //   for(var item of order.items){
  //     builder.addText(FMT.makeLineItem(item.qty, item.title, Currency(item.price).multiply(item.qty)) + "\n");
  //   }
  //   builder.addHLine(1, 501, builder.LINE_THIN);
  // },
  // ticketAddOrderBarcode(builder, order) {
  //   builder.addTextAlign(builder.ALIGN_CENTER);
  //   builder.addText("\n");
  //   builder.addBarcode(order.badgeNumber, builder.BARCODE_CODE39, builder.HRI_NONE, builder.FONT_A, 2, 64);
  //   builder.addText(order.badgeNumber + "\n");
  //   builder.addText("\n");
  // },
  // ticketAddItemCount(builder, order) {
  //   builder.addTextSize(2, 4);
  //   builder.addText(this.itemCount(order.items).toString() + " Items\n");
  //   builder.addTextSize(1, 1);
  //   builder.addText("\n");
  //   builder.addText("\n");
  // },

})();
