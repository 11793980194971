<template>
  <div id="app">
    <div class="app-container">
      <router-view/>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
      title: "Main App",
    }
  },
  mounted () {
    this.$store.dispatch('initialLoad');
    console.log( "------------------------------[ App.vue - mounted() " );
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
  methods: {
    updateAvailable(message) {
      alert(message);
    },
    refreshApp() {
      console.log("clicked refreshApp()");
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
    showRefreshUI(e) {
      // this fires but doesn't show.
      console.log("app.vue->showRefreshUI()");
      this.registration = e.detail;
      this.updateExists = true;
    },    
  }
}
</script>

<style scoped>
  .app-container {
    margin-top: 48px !important;
  }
</style>
