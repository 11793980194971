/* eslint-disable */
'use-strict';

import GeneralService from '@/services/GeneralService';
import _              from "lodash";

var DAH = {};
export default DAH;

(function(){


  //---------------------------------------------------------------------------------------[ Get menu items connected to a category ]
  DAH.getItems = async function( category ) {   // caller should have an active try()
    let response = await GeneralService.getItems( category );

// console.log( "DAH:getItems()" , response.data );

    return response.data;
  }

  //---------------------------------------------------------------------------------------[ Get & Filter Category Listing ]
  //
  //  The workstation has the ability to further filter the menu categories that are
  //  showed on the order screen, and this will filter that list based on "activeCategories"
  //
  DAH.getCategories = async function( categoryList ) {
    let categories = JSON.parse( localStorage.getItem( 'categories' ));
    // let posConfig = JSON.parse( localStorage.getItem( 'posConfig' ));
    if( categories && categories.length > 0 ) {     // has to exist AND have some entries to be valid, else let's go get some...
      return categories;
    }

    try {
      let result = await GeneralService.getCategories( categoryList );
      categories = result.data;
    } catch( error ) {
      console.log( error.message ); // oops
      return [];
    }

    // let activeCategories = posConfig.activeCategories;
    let activeCategories = categories;

    for( let index=0;index<categories.length;index++ ) {
      if( !categories[index].items && categories[index].itemType === 'item') {
        if( categories[index].category && (_.findIndex( activeCategories , { category: categories[index].category }) >= 0)) {  // if no category, no items, or not allowed
          try {
            let items = await DAH.getItems( categories[index].category );
            if( items ) {
              items.forEach( i => {
                i.qty = 0;
                i.itemType=categories[index].itemType
                if( ! i.sequence && i.sequence != 0 ) {
                  i.sequence = 100;
                }
              }              );
              items = _.sortBy( items, ['sequence','title'] );
              categories[index].items = items;
            }
          } catch( error ) {
            categories[index].items = null;
            console.log( error.message );
          }
        } else {
          categories[index].items = null
        }
      } else {
        //console.log( "Not an item or has items already" , index );
      }
    }
    categories = categories.filter( c => { return c.category && c.items &&  c.items.length>0  }); // un-active categories do not have items
    localStorage.setItem( 'categories' , JSON.stringify( categories ));   // this is the "compacted" list
    return categories;
  }

  return DAH;

})();
