<template>
  <div class="ts-keypad" id="ts-keyboard">
    <div v-if="title" class="ts-keypad-title">{{title}}</div>
    <input id="keypadInput" class="ts-keypad-inputarea" type="text" v-model="keypadInput" @input="verifyInput" />
    <b-row>
      <b-col class="ts-keypad-keysarea" style="">
        <table id="VirtualKey" class="">
          <tr>
            <td id="btn7" class="ts-single"  @click="keyPress('7')">7</td>
            <td id="btn8" class="ts-single"  @click="keyPress('8')">8</td>
            <td id="btn9" class="ts-single"  @click="keyPress('9')">9</td>
          <tr/>
          <tr>
            <td id="btn4" class="ts-single"  @click="keyPress('4')">4</td>
            <td id="btn5" class="ts-single"  @click="keyPress('5')">5</td>
            <td id="btn6" class="ts-single"  @click="keyPress('6')">6</td>
          <tr />
          <tr>
            <td id="btn1" class="ts-single"  @click="keyPress('1')">1</td>
            <td id="btn2" class="ts-single"  @click="keyPress('2')">2</td>
            <td id="btn3" class="ts-single"  @click="keyPress('3')">3</td>
          <tr />
          <tr>
            <td class="ts-single" style="background:none;dsiplay:none;"></td>
            <td id="btn0" class="ts-single"  @click="keyPress('0')" >0</td>
            <td id="dot" class="ts-single"  @click="keyPress('.')" >{{isShowDecimal ? "." : ""}}</td>
          </tr>
        </table>
      </b-col>
      <b-col class="ts-keypad-functionsarea" style="">
        <table>
          <tr>
            <td>&nbsp;</td>
            <!-- <td id="btnFeature1" class="ts-v-double" @click="feature(1)" style="background:black;color:white;"></td> -->
          </tr>
          <tr>
            <td>&nbsp;</td>
            <!-- <td id="btnFeature2" class="ts-v-double" @click="feature(2)" style="background:black;color:white;"></td> -->
          </tr>
          <tr>
            <td id="btnFeature3" class="ts-f-double" @click="clear()" style="background:firebrick;color:white;">Clear</td>
          </tr>
          <tr>
            <td id="btnFeature4" class="ts-f-double" @click="enter()" style="background:darkgreen;color:white;">ENTER</td>
          </tr>

        </table>
      </b-col>
    </b-row>
  </div>
</template>
<script>


export default {
  name: 'keypad',
  components: {
  },
  props: {
    title          : { type: String, default: null },
    isShowDecimal  : { type: Boolean, default: true },
    decimalPlaces  : { type: Number, default: 2 },
    minValue       : { type: Number, default: 0 },
    maxValue       : { type: Number, default: 9999.99 },
    maxLen         : { type: Number, default: 6 },
    backgroundColor: { type: String, default: "" }
  },
  data () {
    return {
      keypadInput: ""
    }
  },
  activated() {
    this.keypadInput = "";
  },
  methods: {

    keyPress(e) {
      if( this.keypadInput.length >= this.maxLen ) {
        return;
      }
      this.keypadInput = this.keypadInput + e;
    },

    clear() {
      if( this.keypadInput.length <= 0 ) {
        this.$emit("cancel");
      } else {
        this.keypadInput = "";
      }
    },

    verifyInput() {
      if( this.keypadInput.length >= this.maxLen ) {
        this.keypadInput = this.keypadInput.substring(0,this.maxLen);
      }
    },

    feature( f ) {

      this.$emit( ['feature1','feature2'][f] , this.keypadInput );
    },

    backspace() {
      this.keypadInput = this.keypadInput.substr(0, this.keypadInput.length - 1);
    },

    enter() {
      let value = this.keypadInput;
      this.clear();

      if( value.indexOf( "." ) < 0 ) {
        value = (parseFloat( value ) / 100).toFixed( this.decimalPlaces ).toString();
      } else {
        value = (parseFloat( value )).toFixed( this.decimalPlaces ).toString();
      }

      this.$emit( "click" , value );
    }

  }
}
</script>
<style lang="scss">

  .ts-keypad {
    
    table {
      border-collapse:separate;
    }
    z-index:3000;
    position:absolute;
    top:40%;
    left:20%;
    margin:0;
    padding:0;
    
    border-radius:8px;
    border:3px darkolivegreen solid;
   
    width: 360px;
    height:365px;
    background: #eee;
    color:gray;
    padding:10px;
    box-shadow: 0 0 8px 5px;

    // transition: width 2s, height 2s ease;
    // opacity: 0;
    // transition: all 1s ease-in;
    // transition: all 2s ease 0s;
  }



  .ts-keypad-title {
    text-align:center;
    background:darkolivegreen;
    color:white;
    font-size:24px;
  }

  .ts-keypad-inputarea {
    width:100%;
    font-size: 24px;
    font-weight:500;
    text-align:right;
  }

  .ts-keypad-keysarea {
    margin-top:1em;
    display:inline-block;
    border-spacing:10px;

    td {
      background:darkolivegreen;
      color:white;
      border-radius:6px;
      // margin:10px;
    }
  }

 .ts-keypad-functionsarea {
    width:100%;
    display:inline-block;
    table {
      width:100%;
      font-size:20px;
    }
  }

  .ts-f-double {
    border-radius:1em;
    min-height:64px;
    height:64px;
    font-weight:500;
    font-size:24px;
    text-align:center;
    vertical-align:middle;
    cursor:pointer;
  }

  .ts-single {
    height:54px;
    width:54px;
    font-weight:600;
    font-size:36px;
    text-align:center;
    cursor:pointer;

  }

  .ts-double{
    height:54px;
    width:108px;
    margin:2px;
    font-weight:600;
    font-size:36px;
    text-align:center;
    cursor:pointer;
  }


</style>
