/* eslint-disable */
///-------------------------------------------------------------------------------------------------
// summary:	General Services
///-------------------------------------------------------------------------------------------------

import Api from './Api';

export default {

  getExample() {
    const response = Api().get('/getExample');
    return response;
  },
  getPosConfig( lastUpdateId = '') {
    const response = Api().get(`/api/1/posGetConfig/${lastUpdateId}`);
    return response;
  },
  getCategories(categoryList=null) {
    const response = Api().get(`/api/1/itemCategories/pos`, {params: {categoryList:categoryList}});  // get all the "pos" item categories;
    return response;
  },
  getItems(category) {
    const response = Api().get(`/api/1/items/${category}` , {params: {sortOrder: '{"sequence":1,"title":1}'}});  // Get the items in this category
    return response;
  },
  lookupAccountByName(nameSearch) {
    const response = Api().get(`/api/1/pos/lookupAccountByNameWithTTL/${nameSearch}`);  // Lookup an account number by name search and return the account/badge details
    return response;
  },
  lookupAccount(badgeNumber) {
    const response = Api().get(`/api/1/pos/lookupAccountByBadgeWithTTL/${badgeNumber}`);  // Lookup an account number by badge and return the account/badge details
    return response;
  },
  lookupBadgeWithLimits(badgeNumber) {
    const response = Api().get(`/api/1/badge/${badgeNumber}`);  // Get a badge record with allowed total classes
    return response;
  },
  transactionPost(transaction) {
    const response = Api().post('/api/1/pos/postTransaction', transaction);
    return response;
  },
  postReceipt(receiptRecord) {
    const response = Api().post("/api/1/pos/postReceipt", receiptRecord);
    return response;
  },
  getItemByUPC(upc) {
    const response = Api().get(`/api/1/itemNumberByUPCWithCategory/${upc}`);  // Get a badge record with allowed total classes
    return response;
  }

}
